import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDatlbwttCp_FHgo8lj2OWHu1dagVoU5m0",
  authDomain: "laatingar-a8good.firebaseapp.com",
  databaseURL: "https://laatingar-a8good.firebaseio.com",
  projectId: "laatingar-a8good",
  storageBucket: "laatingar-a8good.appspot.com",
  messagingSenderId: "1010583702323",
  appId: "1:1010583702323:web:8c2814e0ceb6121fcd4c46",
  measurementId: "G-EQJ48JNW0H",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);

export { app, db, functions, auth };
