import React, { useState } from "react";
// import firebase from '../firebase';
import { auth } from "../firebase";
import { sendPasswordResetEmail } from "firebase/auth";

import {
  Button,
  TextField,
  Paper,
  Typography,
  Container,
} from "@material-ui/core";
import PasswordResetStyles from "../styles/PasswordResetStyles";

const useStyles = PasswordResetStyles;

const PasswordReset = ({ history }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      history.push("/");
    } catch (error) {
      console.error("Error sending password reset email: ", error);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Paper square={false} className={classes.container}>
          <div className={classes.innerContainer}>
            <Typography variant="h5" gutterBottom>
              Password Reset
            </Typography>
            <Typography variant="body1" gutterBottom>
              Enter your email, if we have it in our database we’ll send you
              instructions on how to reset your password.
            </Typography>
            <form className={classes.form} onSubmit={onSubmit}>
              <TextField
                value={email}
                variant="outlined"
                margin="normal"
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                type="email"
                autoComplete="email"
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Send me reset email
              </Button>
            </form>
          </div>
        </Paper>
      </div>
    </Container>
  );
};

export default PasswordReset;
