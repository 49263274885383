import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Auth } from "../contexts/AuthContext";
import { PrivateRoute } from "../configs/PrivateRoute";
import Header from "./Header";
import Home from "./Home";
import Dashboard from "./Dashboard";
import Signup from "./Signup";
import Signin from "./Signin";
import PasswordReset from "./PasswordReset";
import Footer from "./Footer";
import AppStyles from "../styles/AppStyles";

const useStyles = AppStyles;

const App = () => {
  const classes = useStyles();

  return (
    <Auth>
      <BrowserRouter>
        <div className={classes.root}>
          <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/signin" exact component={Signin} />
            <Route path="/signup" exact component={Signup} />
            <Route path="/passwordreset" exact component={PasswordReset} />
            <PrivateRoute path="/dashboard/:id?" exact component={Dashboard} />
            <Route path="*" component={Home} />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    </Auth>
  );
};

export default App;
