import React, { useState, forwardRef } from "react";
import { TextField, Button } from "@material-ui/core";
import EditModalStyles from "../styles/EditModalStyles";

const useStyles = EditModalStyles;

const EditModal = forwardRef(({ title, type, label, updateProfile }, ref) => {
  const [data, setData] = useState("");
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    updateProfile(e, label, data); // Pass the event, label, and data to the updateProfile function
  };

  return (
    <div className={classes.paper}>
      <h3>{title}</h3>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          id="outlined-basic"
          label={label}
          type={type}
          onChange={(e) => setData(e.target.value)}
          variant="outlined"
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          fullWidth
        >
          Edit
        </Button>
      </form>
    </div>
  );
});

export default EditModal;
