// import firebase, { db } from '../firebase';
import { functions, db, auth, app } from "../firebase";
import { sendEmailVerification } from "firebase/auth";

export const emailVerification = async (user) => {
  // Ensure the user is not already verified
  if (user && !user.emailVerified) {
    try {
      await sendEmailVerification(user);
      console.log("Verification email sent.");
      return true;
    } catch (error) {
      console.error("Error sending verification email: ", error);
      return false;
    }
  }
};

export const createCheckout = async (plan, currentUser, stripePromise) => {
  const docRef = await db
    .collection("customers")
    .doc(currentUser.uid)
    .collection("checkout_sessions")
    .add({
      price: plan,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });
  // Wait for the CheckoutSession to get attached by the extension
  docRef.onSnapshot(async (snap) => {
    const { error, sessionId } = snap.data();
    if (error) {
      alert(`An error occured: ${error.message}`);
    }
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    }
  });
};

export const redirectPortal = async () => {
  const functionRef = app()
    .functions("us-central1")
    .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");
  const { data } = await functionRef({ returnUrl: window.location.origin });
  window.location.assign(data.url);
};

export const timeConverter = (a) => {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var day = a.getDate();
  var hours = a.getHours();
  var minutes = "0" + a.getMinutes();
  var seconds = "0" + a.getSeconds();
  var time =
    month +
    " " +
    day +
    " " +
    year +
    " " +
    hours +
    ":" +
    minutes.substr(-2) +
    ":" +
    seconds.substr(-2);
  return time;
};
