import { makeStyles } from '@material-ui/core';

const AccountSettingsStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    marginTop: '20px',
    marginBottom: '10px',
    color: 'white'
  },
  profileItem: {
    fontWeight: '600'
  }
}));

export default AccountSettingsStyles;
