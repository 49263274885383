import { makeStyles } from '@material-ui/core';

const FooterStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto'
  },
  footerItem: {
    display: 'block'
  }
}));

export default FooterStyles;
