import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { loadStripe } from '@stripe/stripe-js';
import { createCheckout, redirectPortal } from '../utils';
import Tic from '../images/tic.svg';
import { Container, Grid, Typography, Button } from '@material-ui/core';
import PlansStyles from '../styles/PlansStyles';

const useStyles = PlansStyles;

const tiers = [
  {
    title: 'Basic',
    price: '0',
    plan: 'free',
    description: [
      'Occasional use',
      'Browser extension',
      'Limited page romanization'
    ],
    buttonText: 'Select',
    buttonVariant: 'contained'
  },
  {
    title: 'Premium',
    price: '3.99',
    plan: 'price_1HbCybHDnUN2ZkaLPBKklxIQ',
    description: [
      'Regular use',
      'Browser extension',
      'Unlimited page romanization'
    ],
    buttonText: 'Select',
    buttonVariant: 'contained'
  }
];

const stripePromise = loadStripe(
  'pk_live_51H7tbxHDnUN2ZkaLc1jYE5gRfZMNPJ9WaH7hLBGMj4UxFuoHMIPI6BYndD32rRnd2T0gHunDY8SjVCZPt5cysO6300JZGlTbjC'
);

const Plans = ({ setPending }) => {
  const { currentUser, currentPrice, subscription } = useContext(AuthContext);

  const classes = useStyles();

  return (
    <Container className={classes.PlansContainer}>
      <Typography variant='h4' component='h1' gutterBottom>
        Subscription
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={4} className={classes.col} />
        {tiers.map((tier) => (
          <Grid item key={tier.title} xs={4} className={classes.col}>
            <Typography variant='h5' component='h2' gutterBottom>
              {tier.title}
            </Typography>
            <div className={classes.cardPricing}>
              <Typography component='h5' variant='h3' color='textPrimary'>
                ${tier.price}
              </Typography>
              <Typography variant='h6' color='textSecondary'>
                /mo
              </Typography>
            </div>
            <Typography
              component='p'
              variant='body1'
              color='textSecondary'
              gutterBottom
            >
              {tier.description[0]}
            </Typography>
            <Button
              fullWidth
              variant={tier.buttonVariant}
              color='primary'
              className={classes.btn}
              onClick={() => {
                setPending(true);
                subscription
                  ? redirectPortal()
                  : createCheckout(tier.plan, currentUser, stripePromise);
              }}
              disabled={currentPrice === tier.plan}
            >
              {currentPrice === tier.plan ? 'Current plan' : 'Get started'}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant='h5'
            component='h2'
            className={classes.subtitle}
            gutterBottom
          >
            Features
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.row}>
          <div className={classes.rowItem}>
            <Typography
              component='p'
              variant='subtitle2'
              color='textSecondary'
              gutterBottom
            >
              Clarity
            </Typography>
          </div>
          <div className={classes.rowItem} style={{ paddingLeft: '10%' }}>
            <img src={Tic} alt='tic icon' />
          </div>
          <div className={classes.rowItem} style={{ paddingLeft: '10%' }}>
            <img src={Tic} alt='tic icon' />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.row}>
          <div className={classes.rowItem}>
            <Typography
              component='p'
              variant='subtitle2'
              color='textSecondary'
              gutterBottom
            >
              Browser extension (Chrome...)
            </Typography>
          </div>
          <div className={classes.rowItem} style={{ paddingLeft: '10%' }}>
            <img src={Tic} alt='tic icon' />
          </div>
          <div className={classes.rowItem} style={{ paddingLeft: '10%' }}>
            <img src={Tic} alt='tic icon' />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.row}>
          <div className={classes.rowItem}>
            <Typography
              component='p'
              variant='subtitle2'
              color='textSecondary'
              gutterBottom
            >
              Webpage Romanization
            </Typography>
          </div>
          <div className={classes.rowItem}>
            <Typography
              component='p'
              variant='subtitle2'
              color='textSecondary'
              style={{ paddingLeft: '23%' }}
              gutterBottom
            >
              Limited
            </Typography>
          </div>
          <div className={classes.rowItem}>
            <Typography
              component='p'
              variant='subtitle2'
              color='textSecondary'
              style={{ paddingLeft: '23%' }}
              gutterBottom
            >
              Unlimited
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Plans;
