import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { AppBar, Toolbar, Link } from "@material-ui/core";
import HeaderStyles from "../styles/HeaderStyles";
import Logo from "../images/logo.svg";

const useStyles = HeaderStyles;

const Header = () => {
  const { currentUser } = useContext(AuthContext);
  const classes = useStyles();
  return (
    <>
      {!currentUser && (
        <div className={classes.root}>
          <AppBar position="static" color="inherit" elevation={1}>
            <Toolbar disableGutters>
              <div className={classes.logo}>
                <RouterLink to="/">
                  <img
                    src={Logo}
                    className={classes.logoIcon}
                    alt="website logo"
                  />
                </RouterLink>
              </div>
              <>
                <Link
                  variant="button"
                  color="inherit"
                  className={classes.item}
                  component={RouterLink}
                  underline="none"
                  to="/signin"
                >
                  Log In
                </Link>
                {/* <Link
                  href='https://laatingar-test.tribe.so/'
                  target='_blank'
                  variant='button'
                  color='inherit'
                  underline='none'
                  className={classes.item}
                  rel='noopener'
                >
                  Community
                </Link> */}
              </>
            </Toolbar>
          </AppBar>
        </div>
      )}
    </>
  );
};

export default Header;
