import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import EditorPairStyles from "../styles/EditorPairStyles";
import {
  findFarsiWordsNeedConverting,
  convertFarsiTextToLatin,
  conversion_endpoint,
} from "../helpers/conversion";

const useStyles = EditorPairStyles;

const EditorPair = ({ rowsNum, maxChars = 100 }) => {
  const [latinOutput, setlatinOutput] = useState("");
  const [farsiInput, setfarsiInput] = useState("");
  const classes = useStyles();

  const convertToLatin = () => {
    const farsiWordsNeedConverting = findFarsiWordsNeedConverting(farsiInput);
    if (farsiWordsNeedConverting.length > 0) {
      // ask the server to convert new farsi words
      axios({
        method: "POST",
        url: conversion_endpoint,
        data: {
          words: farsiWordsNeedConverting,
          debug: "on",
        },
        headers: {
          "Content-Type": "text/plain", // it is important to keep this as text/plain, other values cause CORS error!
          Accept: "*/*",
        },
      })
        .then(function (response) {
          setlatinOutput(
            convertFarsiTextToLatin(farsiInput, response.data.conversions)
          );
        })
        .catch(function (error) {
          setlatinOutput("Error! Could not reach the API. " + error);
        });
    } else {
      // if conversions are available for all the words, no words needs to be asked from the server.
      setlatinOutput(convertFarsiTextToLatin(farsiInput, []));
    }
  };

  const debouncedGetAnswer = _.debounce(convertToLatin, 250);

  const handleChange = (e) => {
    setfarsiInput(e.target.value);
  };

  useEffect(() => {
    debouncedGetAnswer();
  }, [farsiInput, debouncedGetAnswer]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={6}
        className={classes.editorCol}
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <textarea
          rows={rowsNum}
          defaultValue={latinOutput}
          className={classes.editor}
          style={{ backgroundColor: "transparent" }}
          readOnly="readonly"
          placeholder="vorood faarsi dar samt raast."
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        className={classes.editorCol}
        style={{ direction: "rtl", backgroundColor: "white" }}
      >
        <textarea
          value={farsiInput}
          placeholder="ورود فارسی در سمت راست."
          rows={rowsNum}
          maxLength={maxChars} // Limiting number of characters
          className={classes.editor}
          onChange={handleChange}
          style={{
            backgroundColor: "transparent",
            fontFamily: "Verdana, Arial, sans-serif",
            fontWeight: "bold",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default EditorPair;
