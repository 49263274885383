import { makeStyles } from '@material-ui/core';

const EditorPairStyles = makeStyles((theme) => ({
  editorCol: {
    textAlign: 'center',
    border: '1px solid #cccccc',
    margin: '3px auto',
    padding: '10px',
    borderRadius: '3px'
  },
  editor: {
    width: '100%',
    resize: 'none',
    border: 'none',
    fontSize: '20px',
    outline: 'none'
  }
}));

export default EditorPairStyles;
