import React from 'react';
import ReactDOM from 'react-dom';
import { CircularProgress } from '@material-ui/core';
import LoadingStyles from '../styles/LoadingStyles';

const useStyles = LoadingStyles;

const Loading = () => {
  const classes = useStyles();

  return ReactDOM.createPortal(
    <div className={classes.root}>
      <CircularProgress color='inherit' size='60px' />
    </div>,
    document.body
  );
};

export default Loading;
