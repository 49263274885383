import React from "react";
import { auth } from "../firebase";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithRedirect,
} from "firebase/auth";
import { Button, Grid, Typography } from "@material-ui/core";
import GoogleIcon from "../images/google_icon.svg";
import FacebookIcon from "../images/facebook_icon.svg";

const ProvidersSignin = () => {
  const handleLogin = (provider) => {
    signInWithRedirect(auth, provider);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            const provider = new GoogleAuthProvider();
            handleLogin(provider);
          }}
          disableElevation
          fullWidth
          startIcon={<img src={GoogleIcon} alt="google logo icon" />}
        >
          <Typography variant="button">Continue with Google</Typography>
        </Button>
      </Grid>
      {/* <Grid item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            // var provider = new auth.FacebookAuthProvider();
            const provider = new FacebookAuthProvider();
            handleLogin(provider);
          }}
          disableElevation
          fullWidth
          startIcon={<img src={FacebookIcon} alt="google logo icon" />}
        >
          <Typography variant="button">Continue with Facebook</Typography>
        </Button>
      </Grid> */}
    </Grid>
  );
};

export default ProvidersSignin;
