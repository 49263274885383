import React from 'react';
import ReactDom from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './styles/theme';
import App from './components/App';
import CssBaseline from '@material-ui/core/CssBaseline';

ReactDom.render(
  <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </>,
  document.querySelector('#root')
);
