import React, { useContext, forwardRef } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Button } from '@material-ui/core';
import DeleteModal from '../styles/DeleteModalStyles';

const useStyles = DeleteModal;

const EditModal = forwardRef(({ setOpenAlert, handleClose }, ref) => {
  const { currentUser, subscription } = useContext(AuthContext);
  const classes = useStyles();

  const handleAccountDeletion = () => {
    currentUser
      .delete()
      .then(function () {
        handleClose();
      })
      .catch(function (error) {
        console.error(error);
        setOpenAlert(true);
        handleClose();
      });
  };

  return (
    <div className={classes.paper}>
      <h3>Are you sure you want to delete your account?</h3>
      <Button
        type='submit'
        variant='contained'
        color='primary'
        className={classes.submit}
        disabled={
          subscription.status === 'active' || subscription.status === 'trialing'
        }
        onClick={handleAccountDeletion}
        fullWidth
      >
        DELETE ACCOUNT
      </Button>
    </div>
  );
});

export default EditModal;
