import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#5BB369'
    },
    secondary: {
      main: '#F0F2FC'
    }
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    h3: {
      fontFamily: ['Open Sans', 'sans-serif'],
      fontSize: '2.5rem',
      fontWeight: '700',
      [breakpoints.down('xs')]: {
        fontSize: '1.9rem'
      }
    },
    h4: {
      fontFamily: ['Open Sans', 'sans-serif'],
      fontWeight: '600',
      [breakpoints.down('xs')]: {
        fontSize: '1.625rem'
      }
    },
    h5: {
      fontFamily: ['Open Sans', 'sans-serif'],
      fontWeight: '700',
      [breakpoints.down('xs')]: {
        fontSize: '1.325rem'
      }
    },

    subtitle1: {
      fontSize: '1.2rem',
      fontWeight: '600',
      [breakpoints.down('xs')]: {
        fontSize: '0.8rem'
      }
    },
    subtitle2: {
      fontSize: '1.2rem',
      [breakpoints.down('xs')]: {
        fontSize: '1rem'
      }
    },

    body1: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '1.1rem',
      [breakpoints.down('xs')]: {
        fontSize: '0.8rem'
      }
    },
    button: {
      [breakpoints.down('xs')]: {
        fontSize: '0.7rem'
      }
    }
  }
});

export default theme;
