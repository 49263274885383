import { makeStyles } from '@material-ui/core';

const AppStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default AppStyles;
