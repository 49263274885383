const conversion_endpoint =
  'https://hhyz5zohp5.execute-api.us-east-2.amazonaws.com/production/farsi_to_latin';

// eslint-disable-next-line no-unused-vars
function replaceFarsiSymbols(farsiText) {
  farsiText = farsiText.replace(/۱/g, '1');
  farsiText = farsiText.replace(/۲/g, '2');
  farsiText = farsiText.replace(/۳/g, '3');
  farsiText = farsiText.replace(/۴/g, '4');
  farsiText = farsiText.replace(/۵/g, '5');
  farsiText = farsiText.replace(/۶/g, '6');
  farsiText = farsiText.replace(/۷/g, '7');
  farsiText = farsiText.replace(/۸/g, '8');
  farsiText = farsiText.replace(/۹/g, '9');
  farsiText = farsiText.replace(/۰/g, '0');
  farsiText = farsiText.replace(/؟/g, '?');
  farsiText = farsiText.replace(/؛/g, ';');
  farsiText = farsiText.replace(/،/g, ',');

  return farsiText;
}

function normalizeFarsiText(farsi) {
  // fix arabic ي by converting it to farsi ی
  farsi = farsi.replace(/ي/g, 'ی');
  return farsi;
}

function findFarsiWordsNeedConverting(farsi) {
  farsi = normalizeFarsiText(farsi);

  // eslint-disable-next-line no-misleading-character-class
  const farsiWords = farsi.match(
    /[‌ابپتثجچحخدذرزژسشصضطظعغفقکئگلمنوهیهٔئآأ]+/gi
  ); // this list includes virtual space too

  if (farsiWords == null) return [];

  //search in localStorage and find words that do not have a latin conversion stored there
  let farsiWordsNeedConverting = [];
  for (let i = 0; i < farsiWords.length; i++) {
    if (!(farsiWords[i] in localStorage))
      farsiWordsNeedConverting.push(farsiWords[i]);
  }

  return farsiWordsNeedConverting;
}

/**
 * Converts farsi text to latin text.
 * @param farsi input farsi text.
 * @param conversions dictionary object mapping farsi to latin words, received from the server.
 * @returns {string} latin text.
 */
function convertFarsiTextToLatin(farsi, conversions) {
  farsi = normalizeFarsiText(farsi);
  farsi = replaceFarsiSymbols(farsi);

  // place farsi->latin conversions in localStorage
  for (let farsiWord in conversions) {
    localStorage.setItem(farsiWord, conversions[farsiWord]);
  }

  // Separate the text into segments, some are farsi words and other are in-between segments, such as ' '
  // and ', ' or '( '.
  // eslint-disable-next-line no-misleading-character-class
  const farsiWordsAndInbetweens = farsi.split(
    /([‌ابپتثجچحخدذرزئژسشصضطظعغفقکگلمنوهیهٔآأ]+)/gi
  );

  // then, for each word, detect whether it is farsi or not
  // eslint-disable-next-line no-misleading-character-class
  const farsiRegEx = /[‌ابپتثجچحخدذرزژسشصضطظعغفقکئگلمنوهیهٔئآأ]+/;
  let isFarsiWordFunc = (word) => farsiRegEx.test(word);
  let isFarsiWord = farsiWordsAndInbetweens.map(isFarsiWordFunc);

  // construct latin text
  let latin = '';
  for (let i = 0; i < farsiWordsAndInbetweens.length; i++) {
    if (isFarsiWord[i])
      latin = latin + localStorage.getItem(farsiWordsAndInbetweens[i]);
    else latin = latin + farsiWordsAndInbetweens[i];
  }

  return latin;
}

export {
  findFarsiWordsNeedConverting,
  convertFarsiTextToLatin,
  conversion_endpoint
};
