import { makeStyles } from '@material-ui/core';

const PasswordResetStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    minHeight: '64vh'
  },
  container: {
    paddingTop: '10px',
    backgroundImage:
      'linear-gradient(270deg, #A2C650 -1.49%, #A2C650 23.36%, #5BB369 37.45%, #00747D 53.04%, #1D5E6F 68.8%, #2F4858 207.28%);'
  },
  innerContainer: {
    backgroundColor: '#fafafa',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px'
  },
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: '10px 0',
    color: 'white',
    fontWeight: '600'
  }
}));

export default PasswordResetStyles;
