import React, { useContext, useState } from "react";
import { Redirect, Link as RouterLink } from "react-router-dom";
// import firebase from '../firebase';
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { AuthContext } from "../contexts/AuthContext";
import ProvidersSignin from "./ProvidersSignin";
import {
  Button,
  TextField,
  Link,
  Grid,
  Typography,
  Container,
  Paper,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import SigninStyles from "../styles/SigninStyles";

const useStyles = SigninStyles;

const Signin = () => {
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // On successful sign-in, user will be redirected by the currentUser effect
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  if (currentUser) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Paper square={false} className={classes.container}>
            <div className={classes.innerContainer}>
              <Typography
                component="h1"
                variant="h5"
                className={classes.containerTitle}
                gutterBottom
              >
                Member Login
              </Typography>
              <ProvidersSignin />
              <Typography variant="subtitle1" className={classes.or}>
                OR
              </Typography>
              <form
                className={classes.form}
                onSubmit={
                  showPassword
                    ? onSubmit
                    : (e) => {
                        e.preventDefault();
                        setShowPassword(true);
                      }
                }
              >
                <TextField
                  error={error}
                  value={email}
                  variant="outlined"
                  className={classes.TextInput}
                  margin="normal"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  autoFocus
                />
                {showPassword && (
                  <TextField
                    error={error}
                    value={password}
                    variant="outlined"
                    className={classes.TextInput}
                    margin="normal"
                    required
                    fullWidth
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                )}
                {error && <Alert severity="error">Something went wrong</Alert>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {showPassword ? "Sign In" : "Continue"}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link component={RouterLink} to="/passwordreset">
                      Can’t Log in?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link component={RouterLink} to="/signup">
                      Don't have an account?
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Paper>
        </div>
      </Container>
    </>
  );
};

export default Signin;
