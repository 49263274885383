import { makeStyles } from '@material-ui/core';

const LoadingStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    backgroundColor: 'rgba(255,255,255,0.7)',
    zIndex: '1300'
  }
}));

export default LoadingStyles;
