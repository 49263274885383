/*global chrome*/

import React, { useState, useEffect } from "react";
// import firebase, { db } from '../firebase';
import { functions, db, auth } from "../firebase";
import { getFirestore, collection, doc } from "firebase/firestore";
import { query, where, onSnapshot, getDoc } from "firebase/firestore";

import { httpsCallable } from "firebase/functions";

import Loading from "../components/Loading";

export const AuthContext = React.createContext();

export const Auth = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [currentPrice, setCurrentPrice] = useState("free");
  const [subscription, setSubscription] = useState(false);

  // var getCustomToken = functions().httpsCallable("getCustomToken");
  var getCustomToken = httpsCallable(functions, "getCustomToken");

  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  useEffect(() => {
    const unsubscribeAuthListener = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      var accessToken;
      if (user) {
        const response = await getCustomToken();
        accessToken = response.data;
      } else {
        setPending(false);
        accessToken = null;
      }
      const isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);
      const isFirefox = typeof InstallTrigger !== "undefined";

      if (isChrome) {
        chrome.runtime.sendMessage("cadiomgbpcpihogaleibheelhpakbfop", {
          accessToken,
          user,
        });
      }
      if (isFirefox) {
        await timeout(250);
        window.postMessage(
          JSON.stringify({
            type: "GET_CREDENTIALS",
            accessToken,
            user,
          }),
          "*"
        );
      }
    });
    return () => {
      // Do unmounting stuff here
      unsubscribeAuthListener();
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      const collectionRef = collection(db, "customers");
      const docRef = doc(collectionRef, currentUser.uid);
      const subscriptionsCollectionRef = collection(docRef, "subscriptions");

      const q = query(
        subscriptionsCollectionRef,
        where("status", "in", ["active", "trialing"])
      );

      const unsubscribeFirestore = onSnapshot(
        q,
        async (snapshot) => {
          // In this implementation we only expect one active Subscription to exist
          const subscriptionDoc = snapshot.docs[0];
          if (subscriptionDoc) {
            setSubscription(subscriptionDoc.data());
            const priceRef = subscriptionDoc.data().price; // Assuming price is a DocumentReference
            if (priceRef) {
              try {
                const priceDoc = await getDoc(priceRef);
                if (priceDoc.exists()) {
                  setCurrentPrice(priceDoc.data().id); // Adjust if needed based on your data structure
                  setPending(false);
                }
              } catch (error) {
                console.error("Error fetching price document:", error);
                // Handle any errors in fetching the price document
              }
            }
          } else {
            setSubscription(false);
            setCurrentPrice("free");
            setPending(false);
          }
        },
        function (error) {
          console.log(error);
        }
      );
      return () => {
        // Do unmounting stuff here
        setSubscription(false);
        setCurrentPrice("free");
        unsubscribeFirestore();
      };
    }
  }, [currentUser]);

  return (
    <>
      {pending ? (
        <Loading />
      ) : (
        <AuthContext.Provider
          value={{
            currentUser,
            currentPrice,
            subscription,
          }}
        >
          {children}
        </AuthContext.Provider>
      )}
    </>
  );
};
