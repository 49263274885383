import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { Link, Typography, Box, Grid } from "@material-ui/core";
import FooterStyles from "../styles/FooterStyles";
import Logo from "../images/logoTransparent.svg";

const useStyles = FooterStyles;
const footerItems = [
  { title: "Company", items: ["Plans", "About", "Blog", "Contact Us"] },
  {
    title: "Features",
    items: ["Page Scan", "Chrome Extension", "Share with others"],
  },
  {
    title: "Support",
    items: ["Help Center", "Privacy Policy", "Terms of Service", "Security"],
  },
  // { title: 'Community', items: ['Facebook', 'Instagram', 'Twitter'] }
];
const Footer = () => {
  const { currentUser } = useContext(AuthContext);

  const classes = useStyles();

  return (
    <>
      {!currentUser && (
        <footer className={classes.footer}>
          {/* <Grid container alignItems="flex-start">
            <Grid item md={1} />
            <Grid item xs={12} sm={2} md={2}>
              <img src={Logo} alt="website logo" />
            </Grid>
            {footerItems.map((unit, index) => {
              return (
                <Grid key={index} item xs={6} sm md={2}>
                  <Typography
                    className={classes.footerItem}
                    color="inherit"
                    variant="subtitle1"
                    component="h4"
                  >
                    {unit.title}
                  </Typography>
                  {unit.items.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        className={classes.footerItem}
                        component={RouterLink}
                        color="inherit"
                        to="#"
                      >
                        <Typography variant="body2">{item}</Typography>
                      </Link>
                    );
                  })}
                </Grid>
              );
            })}
            <Grid item md={1} />
          </Grid> */}
          <Box mt={3}>
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href="#">
                Behnevis
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </footer>
      )}
    </>
  );
};

export default Footer;
