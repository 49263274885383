import React, { useState, useContext } from "react";
// import firebase from '../firebase';
import { functions } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { updateEmail, updatePassword, updateProfile } from "firebase/auth";
import { timeConverter } from "../utils";
import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";
import { AuthContext } from "../contexts/AuthContext";
import { IconButton, Modal, Typography, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert, AlertTitle } from "@material-ui/lab";
import AccountSettingsStyles from "../styles/AccountSettingsStyles";

const useStyles = AccountSettingsStyles;

const AccountSettings = () => {
  const { currentUser, subscription } = useContext(AuthContext);
  const { displayName, email, providerData } = currentUser;
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [nameSetting, setName] = useState(displayName);
  const [emailSetting, setEmail] = useState(email);
  const [profileItem, setProfileItem] = useState(1);
  const classes = useStyles();

  // var updateEmail = firebase.functions().httpsCallable("updateEmail");
  var updateEmail = httpsCallable(functions, "updateEmail");

  const handleOpen = (num) => {
    setOpen(true);
    setProfileItem(num);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const updateProfile = (e, label, data) => {
  //   e.preventDefault();
  //   handleClose();
  //   switch (label) {
  //     case "name":
  //       return currentUser
  //         .updateProfile({
  //           displayName: data,
  //         })
  //         .then(function () {
  //           setName(data);
  //         })
  //         .catch(function (error) {
  //           console.error(error);
  //           setOpenAlert(true);
  //         });

  //     case "email":
  //       return currentUser
  //         .updateEmail(data)
  //         .then(async function () {
  //           setEmail(data);
  //           await updateEmail();
  //         })
  //         .catch(function (error) {
  //           console.error(error);
  //           setOpenAlert(true);
  //         });

  //     case "password":
  //       return currentUser
  //         .updatePassword(data)
  //         .then(function () {
  //           console.log("Password updated");
  //         })
  //         .catch(function (error) {
  //           console.error(error);
  //           setOpenAlert(true);
  //         });

  //     default:
  //       return;
  //   }
  // };
  const updateProfile = async (e, label, data) => {
    // e.preventDefault();
    handleClose();
    try {
      switch (label) {
        case "name":
          await updateProfile(currentUser, { displayName: data });
          setName(data);
          break;

        // Updating Email
        case "email":
          try {
            await updateEmail(currentUser, data); // Correctly update email with the Modular SDK
            setEmail(data); // Update email in your component's state
            // ... Additional logic like notifying the user of the update
          } catch (error) {
            console.error(error);
            setOpenAlert(true); // Handle errors, for instance, showing an alert
          }
          break;

        // Updating Password
        case "password":
          try {
            await updatePassword(currentUser, data); // Correctly update password with the Modular SDK
            console.log("Password updated");
            // ... Additional logic like notifying the user of the update
          } catch (error) {
            console.error(error);
            setOpenAlert(true); // Handle errors
          }
          break;

        default:
          return;
      }
    } catch (error) {
      console.error(error);
      setOpenAlert(true);
    }
  };

  const modalSelection = () => {
    switch (profileItem) {
      case 1:
        return (
          <EditModal
            title="Edit name"
            type="string"
            label="name"
            updateProfile={updateProfile}
          />
        );
      case 2:
        return (
          <EditModal
            title="Edit email"
            type="email"
            label="email"
            updateProfile={updateProfile}
          />
        );
      case 3:
        return (
          <EditModal
            title="Edit password"
            type="password"
            label="password"
            updateProfile={updateProfile}
          />
        );
      case 4:
        return (
          <DeleteModal setOpenAlert={setOpenAlert} handleClose={handleClose} />
        );

      default:
        return;
    }
  };

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Account Settings
      </Typography>

      {openAlert && (
        <Alert
          severity="error"
          onClose={() => {
            setOpenAlert(false);
          }}
        >
          <AlertTitle>
            <b>Error</b>
          </AlertTitle>
          This operation is sensitive and requires recent authentication. Log in
          again before retrying this request.
        </Alert>
      )}

      <Typography variant="h5" component="h2" gutterBottom>
        Profile
      </Typography>

      <div>
        <Typography variant="h6" component="h3" className={classes.profileItem}>
          Name{" "}
          <IconButton
            color="primary"
            className={classes.editStyle}
            onClick={() => {
              handleOpen(1);
            }}
          >
            <EditIcon />
          </IconButton>
        </Typography>
        <Typography variant="body1">{nameSetting}</Typography>
      </div>
      <div>
        <Typography variant="h6" component="h3" className={classes.profileItem}>
          email{" "}
          <IconButton
            color="primary"
            className={classes.editStyle}
            onClick={() => {
              handleOpen(2);
            }}
          >
            <EditIcon />
          </IconButton>
        </Typography>
        <Typography variant="body1">{emailSetting}</Typography>
      </div>
      {providerData.some((item) => item.providerId === "password") && (
        <div>
          <Typography
            variant="h6"
            component="h3"
            className={classes.profileItem}
          >
            password{" "}
            <IconButton
              color="primary"
              className={classes.editStyle}
              onClick={() => {
                handleOpen(3);
              }}
            >
              <EditIcon />
            </IconButton>
          </Typography>
          <Typography variant="body1">*****</Typography>
        </div>
      )}

      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<DeleteIcon />}
        onClick={() => {
          handleOpen(4);
        }}
        disabled={
          subscription.status === "active" || subscription.status === "trialing"
        }
      >
        DELETE ACCOUNT
      </Button>
      {subscription && (
        <Alert severity="info">
          {subscription.cancel_at_period_end
            ? `You still have an active subscription until ${timeConverter(
                subscription.cancel_at.toDate()
              )}`
            : "Cancel your subscribtion before deleting the account"}
        </Alert>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        {modalSelection()}
      </Modal>
    </>
  );
};

export default AccountSettings;
