import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import EditorPair from "./EditorPair";

import {
  Paper,
  Grid,
  Typography,
  Button,
  Container,
  Link,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import browserExtensionIcon from "../images/browser_extension.svg";
import dedicatedEditorIcon from "../images/dedicated_editor.svg";
import mobileIcon from "../images/mobile.svg";
import HomeStyles from "../styles/HomeStyles";

import doroodImage from "../images/dorood_bar_shomaa.png";

const useStyles = HomeStyles;

const Home = () => {
  const { currentUser } = useContext(AuthContext);
  const classes = useStyles();

  if (currentUser) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" disableGutters>
        <Grid container className={classes.section}>
          <Grid item sm={1} />
          <Grid item xs={12} sm={5} className={classes.col}>
            <Typography variant="h3" component="h1" gutterBottom>
              Read Farsi text in Roman (Latin) script
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you can speak Persian (Farsi) but are not familiar with Farsi
              writing (“khatte Farsi”), Laatingar is for you.
            </Typography>
            <Typography variant="body1" gutterBottom>
              It converts Farsi to Latin script (“mesle in”) and adds hidden
              Farsi vowels (“harekat-haa”). This is often called Persian
              Romanization.
            </Typography>
            {/* <Button
              variant="contained"
              endIcon={<ChevronRightIcon style={{ color: "white" }} />}
              color="primary"
              className={classes.addButton}
              disableRipple
              disableElevation
              size="large"
            >
              <Typography variant="button" className={classes.buttonText}>
                Add to chrome
              </Typography>
            </Button> */}
          </Grid>
          <Grid item xs={12} sm={5} className={classes.col}>
            <Paper className={classes.paper}>
              <img src={doroodImage} className={classes.conversionImage}></img>
            </Paper>
          </Grid>
          <Grid item sm={1} />
        </Grid>
        {/* <Grid container className={classes.section}>
          <Grid item sm={1} />
          <Grid item xs={12} sm={5} className={classes.col}>
            <Paper className={classes.paper}></Paper>
          </Grid>
          <Grid item xs={12} sm={5} className={classes.col}>
            <Typography variant="h4" component="h2" gutterBottom>
              Connect with Persian Culture
            </Typography>
            <Typography variant="body1" gutterBottom>
              Improve your language skills and connect with Persian culture by
              reading Farsi news and original content.
            </Typography>
            <Grid container alignItems="center">
              <Link href="#">
                <Typography variant="button">Show me how</Typography>
              </Link>
              <ChevronRightIcon color="primary" />
            </Grid>
          </Grid>
          <Grid item sm={1} />
        </Grid> */}
        {/* <Grid container className={classes.section}>
          <Grid item xs={12} className={classes.featuresTitle}>
            <Typography variant="h4" component="h2">
              Works Where You Read
            </Typography>
          </Grid>
          <Grid item sm={1} />
          <Grid item xs={4} sm className={classes.feature}>
            <img
              src={browserExtensionIcon}
              className={classes.featureImage}
              alt="browser extension icon"
            />
            <Typography
              variant="subtitle1"
              component="h3"
              className={classes.featureSubtitle}
            >
              Browser Extension
            </Typography>
            <Typography variant="body1" align="center">
              (Chrome, Firfox)
            </Typography>
          </Grid>
          <Grid item xs={4} sm className={classes.feature}>
            <img
              src={dedicatedEditorIcon}
              className={classes.featureImage}
              alt="dedicated editor icon"
            />
            <Typography
              variant="subtitle1"
              component="h3"
              className={classes.featureSubtitle}
            >
              Dedicated Editor
            </Typography>
          </Grid>
          <Grid item xs={4} sm className={classes.feature}>
            <img
              src={mobileIcon}
              className={classes.featureImage}
              alt="mobile icon"
            />
            <Typography
              variant="subtitle1"
              component="h3"
              className={classes.featureSubtitle}
            >
              Mobile
            </Typography>
            <Typography variant="body1" align="center">
              (coming soon)
            </Typography>
          </Grid>
          <Grid item xs={2} sm={1} />
        </Grid> */}
        <Grid
          container
          className={classes.section}
          style={{ minHeight: "16vh" }}
        >
          <Grid item sm={1} />
          <Grid item xs={12} sm={10}>
            <Typography
              variant="subtitle1"
              component="h3"
              className={classes.subtitleText}
              style={{ borderBottom: "none" }}
            >
              Below is the sample editor. Please log in (free) for the full
              experience.
            </Typography>
            <EditorPair rowsNum={5} maxChars={300} />
          </Grid>
          {/* <Grid item sm={1} />
          <Grid item xs={1} />
          <Grid item xs={10}>
            <EditorPair rowsNum={10} />
          </Grid> */}
          {/* <Grid item xs={1} /> */}
        </Grid>
        {/* <Grid container className={classes.section}>
          <Grid item xs={12} className={classes.description}>
            <Typography variant='h4' component='h2' align='center' gutterBottom>
              Brilliant Writing Awaits
            </Typography>
            <Typography paragraph variant='body1' align='center'>
              Get started for free and find out what you can accomplish with the
              power of Grammarly at your fingertips.
            </Typography>
            <Button
              variant='contained'
              endIcon={<ChevronRightIcon style={{ color: 'white' }} />}
              color='primary'
              className={classes.addButton}
              disableRipple
              disableElevation
              size='large'
            >
              <Typography variant='button' className={classes.buttonText}>
                Add to chrome
              </Typography>
            </Button>
          </Grid>
        </Grid> */}
      </Container>
    </div>
  );
};

export default Home;
