import React, { useContext, useState } from "react";
import { Redirect, Link as RouterLink } from "react-router-dom";
// import firebase from '../firebase';
import { auth } from "../firebase";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { emailVerification } from "../utils";
import { AuthContext } from "../contexts/AuthContext";
import {
  Paper,
  Button,
  TextField,
  Link,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import SignupStyles from "../styles/SignupStyles";

const useStyles = SignupStyles;

const Signup = () => {
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const classes = useStyles();

  // const onSubmit = async (e) => {
  //   e.preventDefault();

  //   await auth
  //     .createUserWithEmailAndPassword(email, password)
  //     .catch(function (error) {
  //       console.error(error);
  //       setError(true);
  //       setErrorMessage(error.message);
  //     });

  //   var user = await auth.currentUser;
  //   if (user) {
  //     user
  //       .updateProfile({
  //         displayName: `${firstName} ${lastName}`,
  //       })
  //       .then(function () {
  //         emailVerification();
  //       })
  //       .catch(function (error) {
  //         console.error(error);
  //       });
  //   }
  // };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (user) {
        await updateProfile(user, {
          displayName: `${firstName} ${lastName}`,
        });
        emailVerification(user); // Ensure emailVerification accepts a user parameter
      }
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMessage(error.message);
    }
  };

  if (currentUser) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Paper square={false} className={classes.container}>
            <div className={classes.innerContainer}>
              <Typography component="h1" variant="h5" align="center">
                Start Romantizing to Persian
              </Typography>
              <Typography className={classes.containerTitle}>
                Let's create your account
              </Typography>
              <form className={classes.form} onSubmit={onSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={error}
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      className={classes.TextInput}
                      required
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={error}
                      variant="outlined"
                      className={classes.TextInput}
                      required
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="lname"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={error}
                      variant="outlined"
                      className={classes.TextInput}
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      fullWidth
                      type="email"
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={error}
                      variant="outlined"
                      className={classes.TextInput}
                      required
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                    />
                  </Grid>
                </Grid>
                {error && <Alert severity="error">{errorMessage}</Alert>}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign Up
                </Button>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link component={RouterLink} to="/signin">
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Paper>
        </div>
      </Container>
    </>
  );
};

export default Signup;
