import { makeStyles } from '@material-ui/core';

const DeleteModalStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'white'
  }
}));

export default DeleteModalStyles;
