import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
// import firebase from "../firebase";
import { auth } from "../firebase";
import { getRedirectResult, signOut } from "firebase/auth";
import { emailVerification, redirectPortal } from "../utils";
import { AuthContext } from "../contexts/AuthContext";
import EditorPair from "./EditorPair";
import AccountSettings from "./AccountSettings";
import Plans from "./Plans";
import Loading from "../components/Loading";
import Logo from "../images/logo2.svg";
import {
  IconButton,
  Link,
  Drawer,
  Grid,
  Divider,
  Container,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import StarIcon from "@material-ui/icons/Star";
import ForumIcon from "@material-ui/icons/Forum";
import HouseIcon from "@material-ui/icons/House";
import { Alert, AlertTitle } from "@material-ui/lab";
import DashboardStyles from "../styles/DashboardStyles";

const useStyles = DashboardStyles;

const Dashboard = (props) => {
  const { id } = props.match.params;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });
  const { currentUser, subscription } = useContext(AuthContext);
  const { emailVerified } = currentUser;
  const [open, setOpen] = useState(isSmall ? false : true);
  const [sent, setSent] = useState(false);
  const [pageNum, setPageNum] = useState(
    parseInt(sessionStorage.getItem("pageNum")) || 1
  );
  const [pending, setPending] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (id) {
      let num = parseInt(id);
      setPageNum(num);
      sessionStorage.setItem("pageNum", num);
    }
    getRedirectResult(auth)
      .then(function (result) {
        if (result && result.user && !emailVerified) {
          emailVerification(result.user);
        }
      })
      .catch((error) => {
        // Handle errors here, such as a failed redirect sign-in
        console.error("Error in redirect result:", error);
      });
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogOut = async () => {
    try {
      await signOut(auth);
      // Sign-out successful.
      sessionStorage.removeItem("pageNum");
      // Redirect or perform other actions after successful sign out, if needed
    } catch (error) {
      // An error happened.
      console.error("Error signing out: ", error);
    }
  };

  const renderPage = () => {
    switch (pageNum) {
      case 1:
        return (
          <div>
            <Typography variant="h4" component="h1" gutterBottom>
              Dashboard
            </Typography>
            <EditorPair rowsNum={isSmall ? 11 : 35} maxChars={99999999} />
          </div>
        );
      case 2:
        return <AccountSettings />;
      case 3:
        return <Plans setPending={setPending} />;
      default:
        return;
    }
  };
  return (
    <>
      {!emailVerified && (
        <Alert severity="error">
          <AlertTitle>
            <strong>Action required</strong>
          </AlertTitle>
          Please verify your email address to unlock your account.{" "}
          <Link
            component="button"
            onClick={async () => {
              if (currentUser && !currentUser.emailVerified) {
                await emailVerification(currentUser);
                setSent(true); // Assuming 'sent' is meant to indicate the email has been sent
              }
            }}
            color="error"
          >
            {sent ? "Resend Email" : "Send Email"}
          </Link>
        </Alert>
      )}
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            {open && (
              <div className={classes.logo}>
                <img
                  src={Logo}
                  className={classes.logoIcon}
                  alt="website logo"
                />
              </div>
            )}

            <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
              {open ? (
                <ChevronLeftIcon style={{ color: "#FAFAFA" }} />
              ) : (
                <ChevronRightIcon style={{ color: "#FAFAFA" }} />
              )}
            </IconButton>
          </div>
          <Divider />
          <div>
            <ListItem
              button
              selected={pageNum === 1}
              onClick={() => {
                setPageNum(1);
                sessionStorage.setItem("pageNum", 1);
              }}
            >
              <ListItemIcon>
                <HouseIcon style={{ color: "#FAFAFA" }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={<Typography variant="subtitle2">Dashboard</Typography>}
              />
            </ListItem>
            <ListItem
              button
              selected={pageNum === 2}
              onClick={() => {
                setPageNum(2);
                sessionStorage.setItem("pageNum", 2);
              }}
            >
              <ListItemIcon>
                <AccountBoxIcon style={{ color: "#FAFAFA" }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={<Typography variant="subtitle2">Account</Typography>}
              />
            </ListItem>
            {/* <ListItem
              button
              selected={pageNum === 3}
              onClick={() => {
                setPageNum(3);
                sessionStorage.setItem("pageNum", 3);
              }}
              disabled={!emailVerified}
            >
              <ListItemIcon>
                <StarIcon style={{ color: "#FAFAFA" }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="subtitle2">Subscription</Typography>
                }
              />
            </ListItem> */}
            {/* {subscription && (
              <ListItem
                button
                onClick={() => {
                  setPending(true);
                  redirectPortal();
                }}
                disabled={!emailVerified}
              >
                <ListItemIcon>
                  <DashboardIcon style={{ color: "#FAFAFA" }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Typography variant="subtitle2">Portal</Typography>}
                />
              </ListItem>
            )} */}
            {/* <ListItem
              button
              component={Link}
              href={"https://laatingar.tribe.so/"}
              target="_blank"
              underline="none"
              color="inherit"
              disabled={!emailVerified}
            >
              <ListItemIcon>
                <ForumIcon style={{ color: "#FAFAFA" }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={<Typography variant='subtitle2'>Community</Typography>}
              />
            </ListItem> */}
            <ListItem button onClick={handleLogOut}>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#FAFAFA" }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={<Typography variant="subtitle2">Log out</Typography>}
              />
            </ListItem>
          </div>
          <Divider />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {renderPage()}
              </Grid>
            </Grid>
          </Container>
        </main>
      </div>
      {pending && <Loading />}
    </>
  );
};

export default Dashboard;
