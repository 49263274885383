import { makeStyles } from "@material-ui/core";

const HomeStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "65%",
  },
  section: {
    minHeight: "46vh",
    width: "100%",
    padding: "10px 0",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 10px",
  },
  feature: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 5px",
  },
  featureImage: {
    width: "90%",
    maxWidth: "120px",
  },
  featuresTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  featureSubtitle: {
    textAlign: "center",
  },
  subtitleText: {
    padding: "0 5px",
    borderBottom: "1px solid #cccccc",
  },
  description: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 5%",
    background:
      "linear-gradient(180deg, #5BB369 0%, rgba(91, 179, 105, 0.760417) 14.13%, rgba(91, 179, 105, 0.465385) 32.48%, rgba(91, 179, 105, 0.184328) 53.05%, rgba(91, 179, 105, 0.0842886) 66.02%, rgba(91, 179, 105, 0.0456166) 84.37%, rgba(91, 179, 105, 0) 100%);",
  },
  addButton: {
    borderRadius: "4px",
    marginTop: "10px",
  },
  buttonText: {
    fontWeight: "600",
    color: "white",
    padding: "3px",
  },
  conversionImage: {
    maxWidth: "100%" /* Maximize width but maintain aspect ratio */,
    maxHeight: "100%" /* Maximize height but maintain aspect ratio */,
    // relative margin
    // marginTop: "20px",
    // margin: auto /* Center it within the Paper */,
    // display: block /* Remove any extra line-height space */,
  },
}));

export default HomeStyles;
