import { makeStyles } from '@material-ui/core';
import Tic from '../images/tic.svg';

const PlansStyles = makeStyles((theme) => ({
  PlansContainer: {
    minWidth: '650px'
  },
  cardPricing: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(1)
  },
  col: {
    minHeight: '200px',
    margin: '5px auto',
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  rowItem: {
    flex: '1 1 33%'
  },
  btn: {
    marginTop: 'auto',
    width: '60%',
    color: 'white',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '600'
  },
  subtitle: {
    borderBottom: '1px solid #dcdcdc'
  }
}));

export default PlansStyles;
