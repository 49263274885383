import { makeStyles } from "@material-ui/core";

const HeaderStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0, // was 1
    marginBottom: "20px",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    marginLeft: "2%",
    flex: 1,
  },
  logoIcon: {
    width: "65%",
  },
  item: {
    marginRight: "3%",
    fontWeight: "600",
  },
}));

export default HeaderStyles;
